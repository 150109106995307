import React, {Component} from 'react'
import Wrapper from '../components/Wrapper';
import { graphql } from 'gatsby';
import PageHeader from "../components/PageHeader";
// import language from "../../data/language.yaml";
import BannerThin from "../components/BannerThin";
import ExternalContent from '../components/ExternalContent';
import {Col, Row, Carousel} from "antd";
import Link from "../components/Link";
import './blog.less';
import Img from "gatsby-image";

class Page extends Component {
    render() {
        let modules = buildPostModules(this.props.data.wordpressPost.acf.content_post);

        const post = this.props.data.wordpressPost;
        const pages = this.props.data.allWordpressPage.edges;
        const acfFields = this.props.data.wordpressPost.acf;

        let seoTitle = acfFields.meta_title;
        let seoDescription = acfFields.meta_description;
        if (seoTitle===''){
            seoTitle = post.title;
        }

        let path = this.props.data.wordpressPost.slug;
        if (path === 'home') path = '';
        // add a trailing slash
        if (path.substr(-1) !== '/' && path !== '') path += '/';

        return (
            <Wrapper
                title={seoTitle}
                description={seoDescription}
                og_title={acfFields.og_title}
                og_description={acfFields.og_description}
                og_image={acfFields.og_image ? acfFields.og_image.localFile.childImageSharp.original.src : null}
                twitter_card_type={acfFields.twitter_card_type}
                twitter_title={acfFields.twitter_title}
                twitter_description={acfFields.twitter_description}
                twitter_image={acfFields.twitter_image ? acfFields.twitter_image.localFile.childImageSharp.original.src : null}
                path={`/${path}`}
                locale={post.translations.current}
                translations={post.translations}
            >
                <PageHeader
                    title = {post.title}
                    videoID = ""
                    image = {post.acf.image.localFile.childImageSharp.fluid}
                    slides = ""
                    headerColor = "primary"
                    // options: primary, secondary, orange, teal, yellow
                    headerStyle = "cutout-small"
                    // options: landing, cutout, cutout-small, full
                    back = "true"
                    date = {post.humanDate}
                    depth = "full"
                />
                <div className="blog-post">
                    <Row gutter={70}>
                        <Col xs={{span: 24}} md={{span: 20, offset: 2}} lg={{span: 16, offset: 4}}>
                            {modules.map((module) => {
                                return module;
                            })}
                        </Col>
                    </Row>
                </div>
                {post.translations.current === "en" &&
                    <BannerThin
                        bgColor = {pages[1].node.acf.thin_banner_background_colour}
                        // options: primary, secondary, orange, teal, yellow, transparent
                        headerColor = {pages[1].node.acf.thin_banner_header_colour}
                        // options: primary, secondary, orange, teal, yellow
                        header = {pages[1].node.acf.thin_banner_header}
                        paraColor = {pages[1].node.acf.thin_banner_para_colour}
                        // options: primary, secondary, orange, teal, yellow
                        para = {pages[1].node.acf.thin_banner_paragraph}
                        btnLink = {pages[1].node.acf.thin_banner_button_link}
                        btnText = {pages[1].node.acf.thin_banner_button_text}
                        btnColor = {pages[1].node.acf.thin_banner_button_colour}
                        // options: primary, secondary, highlight, white
                    />
                }
                {post.translations.current === "cy" &&
                    <BannerThin
                        bgColor = {pages[0].node.acf.thin_banner_background_colour}
                        // options: primary, secondary, orange, teal, yellow, transparent
                        headerColor = {pages[0].node.acf.thin_banner_header_colour}
                        // options: primary, secondary, orange, teal, yellow
                        header = {pages[0].node.acf.thin_banner_header}
                        paraColor = {pages[0].node.acf.thin_banner_para_colour}
                        // options: primary, secondary, orange, teal, yellow
                        para = {pages[0].node.acf.thin_banner_paragraph}
                        btnLink = {pages[0].node.acf.thin_banner_button_link}
                        btnText = {pages[0].node.acf.thin_banner_button_text}
                        btnColor = {pages[0].node.acf.thin_banner_button_colour}
                        // options: primary, secondary, highlight, white
                    />
                }
            </Wrapper>
        )
    }
}

function buildPostModules (modules){
    let response = [];
    for (let module in modules){

        const settings = {
            dots: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            autoplaySpeed: 10000,
            swipeToSlide: true,
        };

        const moduleType = modules[module].__typename;
        let content = '';

        switch (moduleType){

            case 'WordPressAcf_full_text':
                content = <ExternalContent key={modules[module].id}><div dangerouslySetInnerHTML={{__html: modules[module].text }} /></ExternalContent>
                break;

            case 'WordPressAcf_full_image':
                if (modules[module].image) {
                    // Check for a GIF or SVG and serve the file directly
                    let image = null;
                    const mimeType = modules[module].image.mime_type;
                    if (mimeType === 'image/gif' || mimeType === 'image/svg') {
                        image = modules[module].image.localFile.publicURL;
                    } else {
                        image = modules[module].image.localFile.childImageSharp.original.src;
                    }
                    content = <img key={modules[module].id} className="image-full" src={image} alt="" />
                }
                break;

            case 'WordPressAcf_image_text':
                if (modules[module].image) {
                    let image = null;
                    const mimeType = modules[module].image.mime_type;
                    if (mimeType === 'image/gif' || mimeType === 'image/svg') {
                        image = modules[module].image.localFile.publicURL;
                    } else {
                        image = modules[module].image.localFile.childImageSharp.original.src;
                    }
                    content = <ExternalContent key={modules[module].id}>
                        <img className={modules[module].image_side}
                             src={image} alt=""/>
                        <div dangerouslySetInnerHTML={{__html: modules[module].text}}/>
                    </ExternalContent>
                }
                break;

            case 'WordPressAcf_cta_block':
                content = <Link className="btn btn-primary" key={modules[module].id} to={modules[module].cta_url}>
                    <span>{modules[module].cta_button_text}</span>
                    <span className="icon arrow"></span>
                </Link>
                break;

            case 'WordPressAcf_image_slider':
                content = <Carousel {...settings} key={modules[module].id} >
                    {modules[module].images && modules[module].images.map((image) => {
                        return <div className="slide" key={image.id}>
                            { image != null ?
                                <Img fluid={image.localFile.childImageSharp.fluid} />
                                : null }
                        </div>
                    })}
                </Carousel>
                break;

            default:
                // Unknown module
                break;
        }
        if (content) response.push(content);
    }
    return response;
}

export const pageQuery = graphql`
  query QueryBlogPostDetail($id: String!) {
    wordpressPost(id: { eq: $id }) {
      id
      slug
      title
      author {
        id
        name
      }
      translations {
        current
        cy
        en
      }
      date(formatString: "YYYY-MM-DDTHH:MM:SS")
      humanDate: date(formatString: "Do MMM")
      acf {
        image {
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
                fluid(quality: 80, maxWidth: 1440, maxHeight: 640) {
                    ... GatsbyImageSharpFluid
                }
            }
          }    
        }
        excerpt
        meta_title
        meta_description
		og_title
        og_description
        og_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        }
        twitter_title
        twitter_card_type
        twitter_description
        twitter_image {
          localFile {
            childImageSharp {
              original {
                width
                height
                src
              }
            }
          }  
        } 
		content_post {
          __typename
          ...on WordPressAcf_full_text {
            id
            text
          }
          ...on WordPressAcf_full_image {
            id
            image {
              mime_type
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                  fluid(maxHeight: 450, quality: 8) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ...on WordPressAcf_image_slider {
            id
            images {
              id
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                  fluid(maxHeight: 450, quality: 80) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
          ...on WordPressAcf_cta_block {
            id
            cta_desc_text
            cta_button_text
            cta_url
          }
          ...on WordPressAcf_image_text {
            id
            text
            image_side
            image {
              mime_type
              localFile {
                childImageSharp {
                  original {
                    width
                    height
                    src
                  }
                  fluid(maxHeight: 450, quality: 80) {
                    ... GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    },
    allWordpressPage(filter: {template: {eq: "template-blog-list.php"}}) {
        edges {
          node {
            translations {
              current
            }
            acf {       
                thin_banner_background_colour
                thin_banner_header_colour
                thin_banner_para_colour
                thin_banner_header
                thin_banner_paragraph
                thin_banner_button_colour
                thin_banner_button_text
                thin_banner_button_link
              }
          }
        }
      }
  }
`

export default Page
